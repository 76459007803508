<template>
  <div class="box hunt-invite-panel">
    <div class="flex align-center is-marginless">
      <div class="text-is-capitalized has-text-centered push-right">
        <p>{{ day() }}</p>
        <p class="day-of-month">{{ dayOfMonth() }}</p>
        <p>{{ month() }}</p>
      </div>

      <div>
        <p v-if="showTitle" class="hunt-area-name">{{ invite.name }}</p>
        <p>{{ date() }}</p>

        <ButtonGroup size="small" class="push-top">
          <Button
            type="success"
            icon="icon-check"
            :title="$t('hunt.invite.accept')"
            outlined
            @click="accept" />

          <Button
            type="danger"
            icon="icon-x"
            :title="$t('hunt.invite.decline')"
            outlined
            @click="decline" />
        </ButtonGroup>
      </div>
    </div>

    <AnswerHuntInviteDialog
      v-if="answer"
      :invite="invite"
      :answer="answer"
      @close="closeAnswerInviteModal">
    </AnswerHuntInviteDialog>
  </div>
</template>

<script>
import AnswerHuntInviteDialog from '@/components/dialogs/AnswerHuntInviteDialog.vue'

export default {
  components: {
    AnswerHuntInviteDialog
  },

  props: {
    invite: {
      type: Object,
      default: () => {}
    },

    showTitle: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      answer: '',
      status: ''
    }
  },

  methods: {
    day () {
      return this.$dayjs(this.invite.startsAt).format('dddd')
    },

    month () {
      return this.$dayjs(this.invite.startsAt).format('MMMM')
    },

    dayOfMonth () {
      return this.$dayjs(this.invite.startsAt).format('DD')
    },

    date () {
      const startsAt = this.$dayjs(this.invite.startsAt).format('HH:mm')
      const endsAt = this.$dayjs(this.invite.endsAt).format('HH:mm D MMM, YYYY')

      return `${startsAt} - ${endsAt}`
    },

    accept () {
      this.answer = 'accept'
    },

    decline (invite) {
      this.answer = 'decline'
    },

    closeAnswerInviteModal () {
      this.answer = null
    }
  }
}
</script>

<style lang="scss">
.hunt-invite-panel {
  width: 100%;
  overflow: hidden;

  p {
    margin-bottom: 0 !important;
  }

  .hunt-area-name {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .day-of-month {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
</style>
