<template>
  <div class="page" style="display: block !important; overflow-y: auto;">
    <Titlebar>
      <h5 slot="title" class="title is-5 is-marginless">
        {{ $t('hunt.invites') }}
      </h5>
    </Titlebar>

    <div class="container is-fluid">
      <InlineLoader v-if="invites === null" />

      <div v-else>
        <HuntInviteTable v-if="invites.length > 0" class="is-hidden-touch is-block-desktop">
          <HuntInviteTableRow
            v-for="invite in invites"
            :key="invite.id"
            :invite="invite"
            @accept="accept"
            @decline="decline" />
        </HuntInviteTable>

        <div v-if="invites.length > 0" class="is-block-touch is-hidden-desktop">
          <HuntInviteGrid>
            <div v-for="invite in invites" :key="invite.id" class="column is-half">
              <HuntInvitePanel :invite="invite" />
            </div>
          </HuntInviteGrid>
        </div>

        <p v-if="invites && !invites.length">{{ $t('hunt.noInvites') }}</p>
      </div>
    </div>

    <AnswerHuntInviteDialog
      v-if="selectedInvite"
      :invite="selectedInvite"
      :answer="answer"
      @close="closeAnswerInviteModal">
    </AnswerHuntInviteDialog>
  </div>
</template>

<script>
import HuntInviteTable from './HuntInviteTable.vue'
import HuntInviteTableRow from './HuntInviteTableRow.vue'
import HuntInviteGrid from './HuntInviteGrid.vue'
import HuntInvitePanel from '@/views/huntArea/views/hunts/HuntInvitePanel.vue'
import AnswerHuntInviteDialog from '@/components/dialogs/AnswerHuntInviteDialog.vue'

export default {
  components: {
    HuntInviteTable,
    HuntInviteTableRow,
    HuntInviteGrid,
    HuntInvitePanel,
    AnswerHuntInviteDialog
  },

  data () {
    return {
      answer: '',
      selectedInvite: null
    }
  },

  computed: {
    invites () {
      let invites = this.$store.getters['hunt/invites/invites'] || []
      invites.sort((a, b) => a.startsAt < b.startsAt ? -1 : 1)
      return invites
    }
  },

  methods: {
    accept (invite) {
      this.selectedInvite = invite
      this.answer = 'accept'
    },

    decline (invite) {
      this.selectedInvite = invite
      this.answer = 'decline'
    },

    closeAnswerInviteModal () {
      this.selectedInvite = null
      this.answer = ''
    }
  }
}
</script>

<style scoped>
.day-of-month {
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
