<template>
  <tr>
    <td class="text-is-capitalized">
      {{ dayAndMonth() }}
    </td>

    <td>
      {{ invite.name }}
    </td>

    <td>
      {{ date() }}
    </td>

    <td>
      <ButtonGroup size="small" alignment="right">
        <Button
          type="success"
          icon="icon-check"
          :title="$t('hunt.invite.accept')"
          outlined
          @click="accept" />

        <Button
          type="danger"
          icon="icon-x"
          :title="$t('hunt.invite.decline')"
          outlined
          @click="decline" />
      </ButtonGroup>
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    invite: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    dayAndMonth () {
      return dayjs(this.invite.startsAt).format('dddd DD MMMM')
    },

    date () {
      const startsAt = dayjs(this.invite.startsAt).format('HH:mm')
      const endsAt = dayjs(this.invite.endsAt).format('HH:mm D MMM, YYYY')

      return `${startsAt} - ${endsAt}`
    },

    accept () {
      this.$emit('accept', this.invite)
    },

    decline () {
      this.$emit('decline', this.invite)
    }
  }
}
</script>
